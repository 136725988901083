import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export const BasketContexts = createContext();


export const BasketProviders = ({ children }) =>{


const [searchParams] = useSearchParams();
const [hotelId, sethotelId] = useState('')
const [hotelName, sethotelName] = useState('')
const [tableNumber, setTableNumber] = useState( { id: 1, Table: "", selectedTable: 'false', OrderDetails: [], Complete: false })
console.log("tableNumber------------->",tableNumber)

const [count,setCount] = useState(0)


useEffect(()=>{

    const count =  tableNumber.OrderDetails.length
    console.log("count---s---->",count)
    setCount(count)

},[tableNumber])



useEffect(()=>{
  // console.log("searchParams---------->",searchParams)
  const hotelIdurl = searchParams.get('hotelId');
  const hotelNameurl = searchParams.get('hotelName');
  const tableNumberurl = searchParams.get('tableNumber');
  console.log("tableNumberurl--------->",tableNumberurl)
  sethotelId(hotelIdurl)
  sethotelName(hotelNameurl)
  setTableNumber({...tableNumber, Table: tableNumberurl})

},[])


// const foodItemDone = (selectedItem, qty, special) =>{

// setTableNumber({...tableNumber, tableNumber.OrderDetails : [{...selectedItem, quantity : qty, specialNote : special}] } )
// }
const foodItemDone = (selectedItem, qty, special, setFoodItemsModel) => {
    setTableNumber(prevState => ({
        ...prevState,
        OrderDetails: [
            ...prevState.OrderDetails,
            { ...selectedItem, quantity: qty, specialNote: special }
        ]
    }));
    setFoodItemsModel(false)
};


const clearBasketFun = () =>{
    setTableNumber(prevState => ({
        ...prevState,
        OrderDetails : []
    }))
    setBasket(!Basket)
}


const [billVisible,setBillVisible] = useState(false)

const GenrateBillFun = () =>{
    setBillVisible(true)
}

const removeItem = (id) =>{
    const newData = tableNumber.OrderDetails.filter(item => item.id != id)
    console.log("newData---------------------------->",newData)
    setTableNumber(preState=>({
        ...tableNumber,
        OrderDetails: newData
    }))
}

const [Basket,setBasket] = useState(false)

const handleToggleBasket = () => {
    console.log("tableNumber-------_>",tableNumber.OrderDetails.length)

    if(tableNumber.OrderDetails.length == 0){
        toast.info('Your basket is empty.');

    }else{
        setBasket(!Basket);
    }
};


const [editModel,setEditModel] = useState(false)
const [editItem,setEditItem] = useState('')



const editFun = (editItem) =>{
    console.log("editItem------------------------>",editItem)
    setEditItem(editItem)
    setEditModel(!editModel)
}

const editFunDone = (editItem, qty, specialNote) =>{
console.log("first------->",editItem)
console.log("qty------->",qty)
console.log("specialNote------->",specialNote)


// const newData = tableNumber.OrderDetails.map((item)=>{
// // console.log("tableNumber.OrderDetails.map----------->",item)

// item.id == editItem.id? {...item, quantity: qty, specialNote: specialNote} : item
// })


const updatedOrderDetails = tableNumber.OrderDetails.map((item) =>
    item.id === editItem.id
      ? { ...item, quantity: qty, specialNote: specialNote }
      : item
  );

console.log("updatedOrderDetails-------------------->",updatedOrderDetails)


setTableNumber((prevState)=>({
    ...prevState,
    OrderDetails: updatedOrderDetails
}))

setEditModel(!editModel)

}



    return(
        <BasketContexts.Provider value={{hotelId, hotelName, tableNumber, foodItemDone, count, clearBasketFun, removeItem, billVisible, setBillVisible, GenrateBillFun, Basket, setBasket, handleToggleBasket, editModel, setEditModel, editFun, editItem, setEditItem, editFunDone}}>
            {children}
        </BasketContexts.Provider>
    )
}