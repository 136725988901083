// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useContext } from 'react';
// import idly3 from '../assets/Shubham/Idli.jpg'
// import idliyappam from '../assets/Shubham/idliyappam.webp'
// import puttu from '../assets/Shubham/puttu.jpg'
// import paper_dosa from '../assets/Shubham/paper_dosa.jpg'
// import paper_dosa_masala from '../assets/Shubham/paper_dosa_masala.jpg'
// import egg_dosa from '../assets/Shubham/egg_dosa.webp'
// import egg_masala_dosa from '../assets/Shubham/egg_masala_dosa.jpg'
// import veg_biryani from '../assets/Shubham/Veg_Biryani.jpg'
// import Poori from '../assets/Shubham/Poori.jpg'
// import Set_Dosa from '../assets/Shubham/Set-Dosa.jpg'
// import plain_dosa from '../assets/Shubham/plain_dosa.webp'
// import Ghee_Roast_Masala from '../assets/Shubham/Ghee_Roast_Masala.jpg'
// import ghee_roast_dosa from '../assets/Shubham/ghee-roast-dosa.jpg'
// import masala_dosa from '../assets/Shubham/masala-dosa.jpg'
// import Egg_Roast from '../assets/Shubham/Egg_Roast.png'
// import Kadala_Curry from '../assets/Shubham/Kadala_Curry.jpg'
// import Cheru_Payar_Curry from '../assets/Shubham/Cheru_Payar_Curry.jpg'
// import Potato_Bhaji from '../assets/Shubham/Potato_Bhaji.jpg'
// import Green_Peas_Curry from '../assets/Shubham/Green_Peas_Curry.jpg'
// import Kerala_Meals from '../assets/Shubham/Kerala_Meals.jpeg'
// import White_rice from '../assets/Shubham/White-rice.jpg'
// import Plain_Rice from '../assets/Shubham/Plain-Rice.png'
// import Ghee_Rice from '../assets/Shubham/Ghee_Rice.jpeg'
// import Mutton_Biryani from '../assets/Shubham/Mutton_Biryani.jpg'
// import Chicken_Biryani from '../assets/Shubham/Chicken_Biryani.jpg'
// import Veg_Biryani from '../assets/Shubham/Veg_Biryani.jpg'
// import Egg_Biryani from '../assets/Shubham/Egg_Biryani.jpg'
// import Fish_Biryani from '../assets/Shubham/Fish_Biryani.jpg'
// import Biryani_Rice from '../assets/Shubham/Biryani_Rice.jpg'
// import Chicken_Fried_Rice from '../assets/Shubham/Chicken_Fried_Rice.jpg'
// import Paneer_Fried_Rice from '../assets/Shubham/Paneer_Fried_Rice.jpg'
// import Egg_Fried_Rice from '../assets/Shubham/Egg_Fried_Rice.jpg'
// import prawn_fried_rice_chinese from '../assets/Shubham/prawn-fried-rice-chinese.jpg'
// import Kerala_Porotta from '../assets/Shubham/Kerala_Porotta.jpg'
// import Chappathi from '../assets/Shubham/Chappathi.jpg'
// import Cylon_Parotta from '../assets/Shubham/Cylon_Parotta.jpg'
// import { BsFillBasket2Fill } from "react-icons/bs";
// import backgroundImage from '../images/backgroundImage.jpg';
// import icon from "../images/icon_2.png";
// // import BillGenrate from './BillGenrate';
// import { ImSearch } from "react-icons/im";
// import { BasketContexts } from './BasketContexts';
// import FoodItemsModals from './FoodItemsModels';
// import BasketModals from './BasketModels';
// import BillGenrates from './BillGenrates';



// const foodItems = {
//   Breakfast: [
//     { id: 1, name: "Idly 3 Pcs", price: 40, description: "Soft steamed rice cakes served with chutney and sambar.", image :idly3 },
//     { id: 2, name: "Idiyappam 3 Pcs", price: 40, description: "String hoppers made from rice flour, typically served with curry.", image :idliyappam  },
//     { id: 3, name: "Puttu", price: 40, description: "Steamed cylindrical rice cake layered with coconut.", image :puttu  },
//     { id: 4, name: "Poori 3 Pcs", price: 50, description: "Deep-fried puffy bread served with potato masala.", image :Poori  }
//   ],
//   Dosa: [
//     { id: 5, name: "Set Dosa (3 Pcs)", price: 40, description: "Soft and fluffy dosas served in a set of three.", image :Set_Dosa },
//     { id: 6, name: "Plain Dosa", price: 40, description: "Thin and crispy rice pancake served with chutney and sambar.", image :plain_dosa },
//     { id: 7, name: "Paper Dosa", price: 50, description: "Extra thin and crispy dosa, larger in size.", image :paper_dosa },
//     { id: 8, name: "Paper Dosa Masala", price: 50, description: "Crispy paper dosa stuffed with spiced potato filling.", image :paper_dosa_masala },
//     { id: 9, name: "Ghee Roast", price: 50, description: "Crispy dosa roasted with ghee for added flavor.", image :ghee_roast_dosa },
//     { id: 10, name: "Ghee Roast Masala", price: 50, description: "Ghee roast dosa filled with spiced potato masala.", image :Ghee_Roast_Masala },
//     { id: 11, name: "Masala Dosa", price: 50, description: "Classic dosa filled with a spiced potato filling.", image :masala_dosa },
//     { id: 12, name: "Egg Dosa", price: 50, description: "Dosa topped with a layer of egg, making it more filling.", image :egg_dosa },
//     { id: 13, name: "Egg Masala Dosa", price: 60, description: "Dosa filled with spiced egg masala.", image :egg_masala_dosa }
//   ],
//   Curry: [
//     { id: 14, name: "Egg Roast (Single Egg)", price: 50, description: "Eggs cooked in a spicy onion and tomato gravy.", image :Egg_Roast},
//     { id: 15, name: "Kadala Curry", price: 40, description: "Black chickpeas curry made with coconut and spices.", image : Kadala_Curry },
//     { id: 16, name: "Cheru Payar Curry", price: 40, description: "Green gram curry made with coconut and spices." , image :Cheru_Payar_Curry},
//     { id: 17, name: "Potato Bhaji", price: 40, description: "Mildly spiced mashed potatoes with a hint of turmeric.", image :Potato_Bhaji },
//     { id: 18, name: "Green Peas Curry", price: 40, description: "Green peas cooked in a coconut-based gravy.", image : Green_Peas_Curry }
//   ],
//   Lunch: [
//     { id: 19, name: "Kerala Meals", price: 80, description: "Traditional Kerala-style meal with rice, curry, and sides.", image :Kerala_Meals },
//     { id: 20, name: "White Rice Meals", price: 80, description: "Meal served with white rice, curry, and sides.", image :White_rice },
//     { id: 21, name: "Plain Rice", price: 80, description: "Steamed plain rice.", image : Plain_Rice },
//     { id: 22, name: "Ghee Rice", price: 80, description: "Fragrant rice cooked with ghee and mild spices.", image :Ghee_Rice }
//   ],
// Biriyani: [
//     { id: 23, name: "Mutton Biryani", price: 280, description: "Fragrant rice dish cooked with tender mutton pieces.", image :Mutton_Biryani },
//     { id: 24, name: "Chicken Biryani", price: 160, description: "Basmati rice cooked with chicken and spices.", image : Chicken_Biryani },
//     { id: 25, name: "Chicken Biryani (Half)", price: 100, description: "A smaller portion of our signature chicken biryani.", image :Chicken_Biryani },
//     { id: 26, name: "Veg Biryani", price: 100, description: "Biryani made with mixed vegetables and fragrant rice.", image : Veg_Biryani },
//     { id: 27, name: "Egg Biryani", price: 100, description: "Biryani made with boiled eggs and flavorful spices.", image :Egg_Biryani },
//     { id: 28, name: "Egg Biryani (Half)", price: 80, description: "A smaller portion of egg biryani.", image :Egg_Biryani },
//     { id: 29, name: "Fish Biryani", price: 250, description: "Biryani made with marinated fish and spices.", image :Fish_Biryani},
//     { id: 30, name: "Fish Biryani (Half)", price: 150, description: "A smaller portion of fish biryani.", image :Fish_Biryani},
//     { id: 31, name: "Biryani Rice", price: 60, description: "Flavored rice used in biryani, served without meat.", image : Biryani_Rice },
//     { id: 32, name: "Biryani Rice (Half)", price: 40, description: "A smaller portion of biryani rice.", image : Biryani_Rice }
//   ],
//   'Chinese Rice Items': [
//     { id: 33, name: "Veg Fried Rice", price: 80, description: "Fried rice with mixed vegetables and soy sauce.", image : Chicken_Fried_Rice},
//     { id: 34, name: "Paneer Fried Rice", price: 100, description: "Fried rice with paneer cubes and vegetables.", image : Paneer_Fried_Rice },
//     { id: 35, name: "Chicken Fried Rice", price: 120, description: "Fried rice with chicken pieces and soy sauce.", image :Chicken_Fried_Rice },
//     { id: 36, name: "Egg Fried Rice", price: 100, description: "Fried rice with scrambled eggs and vegetables.", image :Egg_Fried_Rice },
//     { id: 37, name: "Prawns Fried Rice", price: 140, description: "Fried rice with prawns and vegetables.", image : prawn_fried_rice_chinese },
//   ],
//   'Bread Items': [
//     { id: 39, name: "Kerala Porotta", price: 15, description: "Layered flatbread made from maida, soft and flaky.", image :Kerala_Porotta },
//     { id: 40, name: "Chappathi", price: 10, description: "Whole wheat flatbread, soft and versatile.", image :Chappathi },
//     { id: 41, name: "Cylon Parotta", price: 25, description: "Thin and crispy parotta, perfect with curries.", image :Cylon_Parotta }
//   ],
//   'Chicken Items (Kerala Style Curry)': [
//     { id: 42, name: "Chicken Curry", price: 100, description: "Traditional Kerala-style chicken curry with coconut.", image :'idly3' },
//     { id: 43, name: "Chicken Roast", price: 120, description: "Dry roasted chicken with a blend of spices.", image :'idly3' },
//     { id: 44, name: "Chicken Varutharachathu", price: 120, description: "Chicken cooked with roasted coconut and spices.", image :'idly3' }
//   ],
//   'Chicken Dry': [
//     { id: 45, name: "Chicken Porichathu", price: 60, description: "Fried chicken marinated with spices.", image :'idly3' },
//     { id: 46, name: "Chicken Fry (Kerala Style)", price: 120, description: "Spicy and crispy Kerala-style fried chicken.", image :'idly3' },
//     { id: 47, name: "Chicken Dry Fry", price: 120, description: "Dry fried chicken with a blend of spices.", image :'idly3' },
//     { id: 48, name: "Chicken Pepper Fry", price: 120, description: "Fried chicken with a peppery kick.", image :'idly3' },
//     { id: 49, name: "Chicken Liver Fry", price: 80, description: "Fried chicken liver, seasoned with spices.", image :'idly3' },
//     { id: 50, name: "Chicken Kabab", price: 80, description: "Skewered and grilled chicken marinated in spices.", image :'idly3' }
//   ],
//   'Chicken (North Indian)': [
//     { id: 51, name: "Chicken Masala", price: 125, description: "Spicy chicken curry with a thick tomato-based gravy.", image :'idly3' },
//     { id: 52, name: "Chicken Pepper Masala", price: 125, description: "Chicken cooked in a spicy pepper-based gravy.", image :'idly3' },
//     { id: 53, name: "Butter Chicken", price: 130, description: "Mild and creamy tomato-based curry with chicken.", image :'idly3' }
//   ],
// }

// const FoodItemss = () => {
// const {hotelId, hotelName, tableNumber, count, Basket, setBasket, handleToggleBasket, billVisible} = useContext(BasketContexts)
//   const location = useLocation();
//   const {category} = location.state;
//   const [foodItemsModel,setFoodItemsModel] = useState(false)
//   const [selectedItem,setSelectedItem] = useState(null)
//   const handleItemClick = (item) => {
//     setSelectedItem(item)
//     setFoodItemsModel(true)
//   };


//   const [searchTerm, setSearchTerm] = useState('');
//   const handleSearchChange = (event) => {
//       setSearchTerm(event.target.value);
//   };

//   const filteredCategories = foodItems[category]?.filter(category =>
//       category.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   console.log("filteredCategories--------->",filteredCategories)

//     return (
//         <div style={styles.container}>
//           <header style={styles.header}>
//                 <div style={styles.headerContent}>
//                     <img src={icon} style={styles.headerIcon} alt="Restaurant Icon" />
//                     <h1 style={styles.headerTitle}>{hotelName}</h1>
//                 </div>
//                  <div style={{flexDirection: 'column',justifyContent:'center',alignItems:'center'}}>
//              <button onClick={handleToggleBasket} style={styles.basketButton}>
//                     <span style={styles.basketCount}>{count}</span>
//                     <BsFillBasket2Fill style={styles.basketIcon} />
//                 </button>
//                     <span style={{fontSize: 20,fontWeight:'600'}}>{tableNumber.Table}</span>
//              </div>
//             </header>
//             <div style={styles.content}>
//             <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
//             <span style={styles.sectionTitle}>{category}</span>
//             </div>
//             {Basket && (
//                 <BasketModals
//                     handleToggleBasket={handleToggleBasket}
//                     tableNumber={tableNumber}
//                     setBasket={setBasket}/>
//             )}
// {billVisible && (
//                 <div>
//                     <BillGenrates order={tableNumber} />
//                 </div>
//             )}
//             <div style={{ position: 'relative', width: '40%' }}>
//                     <ImSearch style={{
//                         position: 'absolute',
//                         top: '50%',
//                         left: '10px',
//                         transform: 'translateY(-50%)',
//                         color: '#7d1a74'
//                     }} />
//                    <input
//                         type="text"
//                         value={searchTerm}
//                         onChange={handleSearchChange}
//                         style={{
//                             width: '100%',
//                             padding: '8px 8px 8px 40px',
//                             border: '1px solid #7d1a74',
//                             backgroundColor: '#f0f0f0',
//                             outline: 'none',
//                             fontSize: '16px'
//                         }}
//                         placeholder='Search'
//                     />
//                     <style jsx>{`
//                         input::placeholder {
//                             color: #7d1a74;
//                         }
//                     `}</style>
//                 </div>
//                 <div style={styles.gridContainer}>
//                     {filteredCategories.map(item => (
//                         <div
//                             key={item.id}
//                             onClick={() => handleItemClick(item)}
//                             style={styles.gridItems}
//                         >
//                             <img
//                                 src={item.image}
//                                 alt={item.name}
//                                 style={styles.image}
//                             />
//                             <span style={styles.foodName}>{item.name}</span>
//                             <span style={styles.foodPrice}>Rs. {item.price}</span>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             {foodItemsModel && (
//                       <FoodItemsModals
//                       selectedItem={selectedItem}
//                       setFoodItemsModel={setFoodItemsModel}/>
//             )}
//         </div>
//     );
// };

// const styles = {
//     container: {
//         height: '100vh',
//         backgroundImage: `url(${backgroundImage})`,
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         backgroundRepeat: 'no-repeat',
//         overflowY: "auto",
//         display: 'flex',
//         flexDirection: 'column',
//     },
//     header: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         padding: '20px',
//         backgroundColor: 'rgba(125, 26, 116, 0.9)',
//         color: 'white',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//     },
//     headerContent: {
//         display: 'flex',
//         alignItems: 'center',
//     },
//     headerTitle: {
//         fontSize: '1.5rem',
//         margin: 0,
//     },
//     basketButton: {
//         background: 'none',
//         border: 'none',
//         cursor: 'pointer',
//         padding: 0,
//         display: 'flex',
//         alignItems: 'center',
//         position: 'relative',
//     },
//     basketIcon: {
//         color: 'white',
//         fontSize: '54px',
//         marginLeft: '10px',
//     },
//     basketCount: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         borderRadius: '50%',
//         backgroundColor: 'red',
//         color: 'white',
//         fontWeight: '800',
//         fontSize: '12px',
//         width: '24px',
//         height: '24px',
//         position: 'absolute',
//         top: '-8px',
//         right: '-8px',
//     },
//     content: {
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         gap: '20px',
//         padding: '10px',
//         backgroundColor: 'rgba(255, 255, 255, 0.9)',
//         borderRadius: '20px',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//         margin: '20px',
//     },
//     foodItemsGrid: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'center',
//         gap: '20px',
//     },
//     foodItemCard: {
//       flex: '1 1 calc(45% - 40px)',
//       maxWidth: 'calc(45% - 40px)',
//         minWidth: '200px',
//         padding: '10px',
//         cursor: 'pointer',
//         borderRadius: '10px',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         textAlign: 'center',
//         transition: 'transform 0.2s',
//         margin: '10px 20px',
//         backgroundColor: 'rgba(255, 205, 255, 0.9)',
//     },

//     image: {
//       width: '100%',
//       height: '150px',
//       objectFit: 'cover',
//       borderRadius: '8px',
//   },
//     foodName: {
//         fontSize: '1.2rem',
//         fontWeight: '800',
//         marginTop: '10px',
//     },
//     foodPrice: {
//         fontSize: '1.4rem',
//         color: '#7d1a74',
//         marginTop: '5px',
//         fontWeight:'600'
//     },
//     header: {
//       display: 'flex',
//       justifyContent: 'space-between',
//       alignItems: 'center',
//       padding: '20px',
//       backgroundColor: 'rgba(125, 26, 116, 0.9)',
//       color: 'white',
//       boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//       flexWrap: 'wrap',
//   },
//   headerContent: {
//       display: 'flex',
//       alignItems: 'center',
//       flexWrap: 'wrap',
//   },
//   headerTitle: {
//       fontSize: '1.5rem',
//       margin: 0,
//   },
//   headerIcon: {
//       height: '50px',
//       marginRight: '15px',
//   },
//   basketButton: {
//       background: 'none',
//       border: 'none',
//       cursor: 'pointer',
//       padding: 0,
//       display: 'flex',
//       alignItems: 'center',
//       position: 'relative',
//       fontSize: '1.5rem',
//   },
//   basketIcon: {
//       color: 'white',
//       fontSize: '2rem',
//       marginLeft: '5px',
//   },
//   basketCount: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       borderRadius: '50%',
//       backgroundColor: 'red',
//       color: 'white',
//       fontWeight: '800',
//       fontSize: '0.8rem',
//       width: '1.5rem',
//       height: '1.5rem',
//       position: 'absolute',
//       top: '-8px',
//       right: '-8px',
//   },
//   sectionTitle: {
//     textAlign: 'center',
//     color: '#7d1a74',
//     fontSize: '2rem',
//     fontWeight: '600',
//     // marginBottom: '20px',
// },
// gridContainer: {
//   display: 'grid',
//   gridTemplateRows: '235px',
//   gridAutoRows: '235px',
//   gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))', // Corrected to a string
//   gridGap: '40px', // Corrected to camelCase
// },
// gridItems: {
//   padding: '6px',
//   cursor: 'pointer',
//   borderRadius: '10px',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   textAlign: 'center',
//   backgroundColor: 'rgba(255, 205, 255, 0.9)',
//           maxWidth: '680px'
// },
// };

// export default FoodItemss;



// Animated 

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import idly3 from '../assets/Shubham/Idli.jpg'
import idliyappam from '../assets/Shubham/idliyappam.webp'
import puttu from '../assets/Shubham/puttu.jpg'
import paper_dosa from '../assets/Shubham/paper_dosa.jpg'
import paper_dosa_masala from '../assets/Shubham/paper_dosa_masala.jpg'
import egg_dosa from '../assets/Shubham/egg_dosa.webp'
import egg_masala_dosa from '../assets/Shubham/egg_masala_dosa.jpg'
import veg_biryani from '../assets/Shubham/Veg_Biryani.jpg'
import Poori from '../assets/Shubham/Poori.jpg'
import Set_Dosa from '../assets/Shubham/Set-Dosa.jpg'
import plain_dosa from '../assets/Shubham/plain_dosa.webp'
import Ghee_Roast_Masala from '../assets/Shubham/Ghee_Roast_Masala.jpg'
import ghee_roast_dosa from '../assets/Shubham/ghee-roast-dosa.jpg'
import masala_dosa from '../assets/Shubham/masala-dosa.jpg'
import Egg_Roast from '../assets/Shubham/Egg_Roast.png'
import Kadala_Curry from '../assets/Shubham/Kadala_Curry.jpg'
import Cheru_Payar_Curry from '../assets/Shubham/Cheru_Payar_Curry.jpg'
import Potato_Bhaji from '../assets/Shubham/Potato_Bhaji.jpg'
import Green_Peas_Curry from '../assets/Shubham/Green_Peas_Curry.jpg'
import Kerala_Meals from '../assets/Shubham/Kerala_Meals.jpeg'
import White_rice from '../assets/Shubham/White-rice.jpg'
import Plain_Rice from '../assets/Shubham/Plain-Rice.png'
import Ghee_Rice from '../assets/Shubham/Ghee_Rice.jpeg'
import Mutton_Biryani from '../assets/Shubham/Mutton_Biryani.jpg'
import Chicken_Biryani from '../assets/Shubham/Chicken_Biryani.jpg'
import Veg_Biryani from '../assets/Shubham/Veg_Biryani.jpg'
import Egg_Biryani from '../assets/Shubham/Egg_Biryani.jpg'
import Fish_Biryani from '../assets/Shubham/Fish_Biryani.jpg'
import Biryani_Rice from '../assets/Shubham/Biryani_Rice.jpg'
import Chicken_Fried_Rice from '../assets/Shubham/Chicken_Fried_Rice.jpg'
import Paneer_Fried_Rice from '../assets/Shubham/Paneer_Fried_Rice.jpg'
import Egg_Fried_Rice from '../assets/Shubham/Egg_Fried_Rice.jpg'
import prawn_fried_rice_chinese from '../assets/Shubham/prawn-fried-rice-chinese.jpg'
import Kerala_Porotta from '../assets/Shubham/Kerala_Porotta.jpg'
import Chappathi from '../assets/Shubham/Chappathi.jpg'
import Cylon_Parotta from '../assets/Shubham/Cylon_Parotta.jpg'
import { BsFillBasket2Fill } from "react-icons/bs";
import backgroundImage from '../images/backgroundImage.jpg';
import icon from "../images/icon_2.png";
// import BillGenrate from './BillGenrate';
import { ImSearch } from "react-icons/im";
import { BasketContexts } from './BasketContexts';
import FoodItemsModals from './FoodItemsModels';
import BasketModals from './BasketModels';
import BillGenrates from './BillGenrates';
import EditItemsModels from './EditItemsModels';
import { toast } from 'react-toastify';



const foodItems = {
  Breakfast: [
    { id: 1, name: "Idly 3 Pcs", price: 40, description: "Soft steamed rice cakes served with chutney and sambar.", image :idly3 },
    { id: 2, name: "Idiyappam 3 Pcs", price: 40, description: "String hoppers made from rice flour, typically served with curry.", image :idliyappam  },
    { id: 3, name: "Puttu", price: 40, description: "Steamed cylindrical rice cake layered with coconut.", image :puttu  },
    { id: 4, name: "Poori 3 Pcs", price: 50, description: "Deep-fried puffy bread served with potato masala.", image :Poori  }
  ],
  Dosa: [
    { id: 5, name: "Set Dosa (3 Pcs)", price: 40, description: "Soft and fluffy dosas served in a set of three.", image :Set_Dosa },
    { id: 6, name: "Plain Dosa", price: 40, description: "Thin and crispy rice pancake served with chutney and sambar.", image :plain_dosa },
    { id: 7, name: "Paper Dosa", price: 50, description: "Extra thin and crispy dosa, larger in size.", image :paper_dosa },
    { id: 8, name: "Paper Dosa Masala", price: 50, description: "Crispy paper dosa stuffed with spiced potato filling.", image :paper_dosa_masala },
    { id: 9, name: "Ghee Roast", price: 50, description: "Crispy dosa roasted with ghee for added flavor.", image :ghee_roast_dosa },
    { id: 10, name: "Ghee Roast Masala", price: 50, description: "Ghee roast dosa filled with spiced potato masala.", image :Ghee_Roast_Masala },
    { id: 11, name: "Masala Dosa", price: 50, description: "Classic dosa filled with a spiced potato filling.", image :masala_dosa },
    { id: 12, name: "Egg Dosa", price: 50, description: "Dosa topped with a layer of egg, making it more filling.", image :egg_dosa },
    { id: 13, name: "Egg Masala Dosa", price: 60, description: "Dosa filled with spiced egg masala.", image :egg_masala_dosa }
  ],
  Curry: [
    { id: 14, name: "Egg Roast (Single Egg)", price: 50, description: "Eggs cooked in a spicy onion and tomato gravy.", image :Egg_Roast},
    { id: 15, name: "Kadala Curry", price: 40, description: "Black chickpeas curry made with coconut and spices.", image : Kadala_Curry },
    { id: 16, name: "Cheru Payar Curry", price: 40, description: "Green gram curry made with coconut and spices." , image :Cheru_Payar_Curry},
    { id: 17, name: "Potato Bhaji", price: 40, description: "Mildly spiced mashed potatoes with a hint of turmeric.", image :Potato_Bhaji },
    { id: 18, name: "Green Peas Curry", price: 40, description: "Green peas cooked in a coconut-based gravy.", image : Green_Peas_Curry }
  ],
  Lunch: [
    { id: 19, name: "Kerala Meals", price: 80, description: "Traditional Kerala-style meal with rice, curry, and sides.", image :Kerala_Meals },
    { id: 20, name: "White Rice Meals", price: 80, description: "Meal served with white rice, curry, and sides.", image :White_rice },
    { id: 21, name: "Plain Rice", price: 80, description: "Steamed plain rice.", image : Plain_Rice },
    { id: 22, name: "Ghee Rice", price: 80, description: "Fragrant rice cooked with ghee and mild spices.", image :Ghee_Rice }
  ],
Biriyani: [
    { id: 23, name: "Mutton Biryani", price: 280, description: "Fragrant rice dish cooked with tender mutton pieces.", image :Mutton_Biryani },
    { id: 24, name: "Chicken Biryani", price: 160, description: "Basmati rice cooked with chicken and spices.", image : Chicken_Biryani },
    { id: 25, name: "Chicken Biryani (Half)", price: 100, description: "A smaller portion of our signature chicken biryani.", image :Chicken_Biryani },
    { id: 26, name: "Veg Biryani", price: 100, description: "Biryani made with mixed vegetables and fragrant rice.", image : Veg_Biryani },
    { id: 27, name: "Egg Biryani", price: 100, description: "Biryani made with boiled eggs and flavorful spices.", image :Egg_Biryani },
    { id: 28, name: "Egg Biryani (Half)", price: 80, description: "A smaller portion of egg biryani.", image :Egg_Biryani },
    { id: 29, name: "Fish Biryani", price: 250, description: "Biryani made with marinated fish and spices.", image :Fish_Biryani},
    { id: 30, name: "Fish Biryani (Half)", price: 150, description: "A smaller portion of fish biryani.", image :Fish_Biryani},
    { id: 31, name: "Biryani Rice", price: 60, description: "Flavored rice used in biryani, served without meat.", image : Biryani_Rice },
    { id: 32, name: "Biryani Rice (Half)", price: 40, description: "A smaller portion of biryani rice.", image : Biryani_Rice }
  ],
  'Chinese Rice Items': [
    { id: 33, name: "Veg Fried Rice", price: 80, description: "Fried rice with mixed vegetables and soy sauce.", image : Chicken_Fried_Rice},
    { id: 34, name: "Paneer Fried Rice", price: 100, description: "Fried rice with paneer cubes and vegetables.", image : Paneer_Fried_Rice },
    { id: 35, name: "Chicken Fried Rice", price: 120, description: "Fried rice with chicken pieces and soy sauce.", image :Chicken_Fried_Rice },
    { id: 36, name: "Egg Fried Rice", price: 100, description: "Fried rice with scrambled eggs and vegetables.", image :Egg_Fried_Rice },
    { id: 37, name: "Prawns Fried Rice", price: 140, description: "Fried rice with prawns and vegetables.", image : prawn_fried_rice_chinese },
  ],
  'Bread Items': [
    { id: 39, name: "Kerala Porotta", price: 15, description: "Layered flatbread made from maida, soft and flaky.", image :Kerala_Porotta },
    { id: 40, name: "Chappathi", price: 10, description: "Whole wheat flatbread, soft and versatile.", image :Chappathi },
    { id: 41, name: "Cylon Parotta", price: 25, description: "Thin and crispy parotta, perfect with curries.", image :Cylon_Parotta }
  ],
  'Chicken Items (Kerala Style Curry)': [
    { id: 42, name: "Chicken Curry", price: 100, description: "Traditional Kerala-style chicken curry with coconut.", image :'idly3' },
    { id: 43, name: "Chicken Roast", price: 120, description: "Dry roasted chicken with a blend of spices.", image :'idly3' },
    { id: 44, name: "Chicken Varutharachathu", price: 120, description: "Chicken cooked with roasted coconut and spices.", image :'idly3' }
  ],
  'Chicken Dry': [
    { id: 45, name: "Chicken Porichathu", price: 60, description: "Fried chicken marinated with spices.", image :'idly3' },
    { id: 46, name: "Chicken Fry (Kerala Style)", price: 120, description: "Spicy and crispy Kerala-style fried chicken.", image :'idly3' },
    { id: 47, name: "Chicken Dry Fry", price: 120, description: "Dry fried chicken with a blend of spices.", image :'idly3' },
    { id: 48, name: "Chicken Pepper Fry", price: 120, description: "Fried chicken with a peppery kick.", image :'idly3' },
    { id: 49, name: "Chicken Liver Fry", price: 80, description: "Fried chicken liver, seasoned with spices.", image :'idly3' },
    { id: 50, name: "Chicken Kabab", price: 80, description: "Skewered and grilled chicken marinated in spices.", image :'idly3' }
  ],
  'Chicken (North Indian)': [
    { id: 51, name: "Chicken Masala", price: 125, description: "Spicy chicken curry with a thick tomato-based gravy.", image :'idly3' },
    { id: 52, name: "Chicken Pepper Masala", price: 125, description: "Chicken cooked in a spicy pepper-based gravy.", image :'idly3' },
    { id: 53, name: "Butter Chicken", price: 130, description: "Mild and creamy tomato-based curry with chicken.", image :'idly3' }
  ],
}

const FoodItemss = () => {
    const { hotelId, hotelName, tableNumber, count, Basket, setBasket, handleToggleBasket, billVisible, editModel } = useContext(BasketContexts);
    console.log("tableNumber---------------->",tableNumber)
    const location = useLocation();
    const { category } = location.state;
    const [foodItemsModel, setFoodItemsModel] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleItemClick = (item) => {

        const result = tableNumber.OrderDetails.some((Orderitem)=> Orderitem.id == item.id)

        // const result = tableNumber.OrderDetails.some((Orderitem)=> {
        //     // Orderitem == item
        //     console.log("Orderitem--------_>",Orderitem)
        //     console.log("item--------_>",item)
        // })


     
console.log("result-------------->",result)

if(result){

    toast.info('This item already exists in your basket');

}else{

    setSelectedItem(item);
    setFoodItemsModel(true);
}
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredCategories = foodItems[category]?.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        // Add fade-in effect when component loads
        document.querySelectorAll('.fade-in').forEach(element => {
            element.style.opacity = '1';
            element.style.transform = 'translateY(0)';
        });
    }, []);
    

    return (
        <div style={styles.container}>
            <header style={styles.header}>
            <div style={styles.headerContent}>
                    <img src={icon} style={styles.headerIcon} alt="Restaurant Icon" />
                    <h1 style={styles.headerTitle}>{hotelName}</h1>
                </div>
                <div style={styles.basketSection}>
                    <button onClick={handleToggleBasket} style={styles.basketButton}>
                        <span style={styles.basketCount}>{count}</span>
                        <BsFillBasket2Fill style={styles.basketIcon} />
                    </button>
                    <span style={styles.tableNumber}>{tableNumber.Table}</span>
                </div>
            </header>
            {foodItemsModel && (
                    <FoodItemsModals
                        selectedItem={selectedItem}
                        setFoodItemsModel={setFoodItemsModel}
                    />
                )}

            {Basket  && (
                <BasketModals
                    handleToggleBasket={handleToggleBasket}
                    tableNumber={tableNumber}
                    setBasket={setBasket}
                />
            )}

{editModel && (
                <EditItemsModels />
            )}

            {billVisible && (
                <div>
                    <BillGenrates order={tableNumber} />
                </div>
            )}
           

            <div style={styles.content} className="fade-in">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={styles.sectionTitle}>{category}</span>
                </div>

                <div style={styles.searchContainer}>
                    <ImSearch style={styles.searchIcon} />
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={styles.searchInput}
                        placeholder='Search'
                    />
                </div>


                <div style={styles.gridContainer}>
                    {filteredCategories.map(item => (
                        <div
                            key={item.id}
                
                            style={styles.categoryCard}
                            className={foodItemsModel? 'null' : "category-card"}
                        >
                            <img
                                src={item.image}
                                alt={item.name}
                                style={styles.image}
                            />
                            <div>
                                <div style={styles.itemDetails}>
                                    <span style={styles.itemName}>{item.name}</span>
                                    <span style={styles.itemPrice}>{item.price} Rs.</span>
                                </div>
                                <div style={styles.textDescriptionContainer}>
                                    <span style={styles.textDescription}>{item.description}</span>
                                </div>
                                <button style={styles.addButton} onClick={() => handleItemClick(item)}>
                                    Add to basket
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        backgroundColor: 'rgba(125, 26, 116, 0.9)',
        color: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        flexWrap: 'wrap',
        transition: 'all 0.3s ease',
        justifyContent: 'space-between'
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
    },
    headerTitle: {
        fontSize: '1.5rem',
        margin: 0,
    },
    headerIcon: {
        height: '50px',
        marginBottom: '10px',
    },
    basketSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
    },
    basketButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        fontSize: '1.5rem',
        transition: 'transform 0.2s ease-in-out',
    },
    basketIcon: {
        color: 'white',
        fontSize: '2rem',
        marginLeft: '5px',
    },
    basketCount: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: 'red',
        color: 'white',
        fontWeight: '800',
        fontSize: '0.8rem',
        width: '1.5rem',
        height: '1.5rem',
        position: 'absolute',
        top: '-8px',
        right: '-8px',
    },
    tableNumber: {
        fontSize: '1.2rem',
        fontWeight: '600',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        margin: '10px',
    },
    searchContainer: {
        position: 'relative',
        width: '40%',
        // margin: '0 auto',
    },
    searchIcon: {
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        color: '#7d1a74',
    },
    searchInput: {
        width: '100%',
        padding: '8px 8px 8px 40px',
        border: '1px solid #7d1a74',
        backgroundColor: '#f0f0f0',
        outline: 'none',
        borderRadius: '5px',
        fontSize: '1rem',
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: '20px',
        padding: '10px',
    },
    categoryCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(125, 26, 116, 0.5)',
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        height: 'auto', // Adjusts height based on content
    },
    image: {
        width: '100%',
        height: '100%',
        maxHeight: '150px',
        borderRadius: '10px',
        objectFit: 'cover',
    },
    itemDetails: {
        padding: '5px',
    },
    itemName: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    itemPrice: {
        fontSize: '0.9rem',
        color: '#7d1a74',
        fontWeight: '800',
    },
    textDescriptionContainer: {
        border: '0px solid red',
    },
    textDescription: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#666',
    },
    addButton: {
        marginTop: '10px',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#7d1a74',
        color: 'white',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        marginBottom: '0px',
        fontWeight: '800'
    },
    sectionTitle: {
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#7d1a74',
        marginBottom: '15px',
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    headerTitle: {
        fontSize: '1.5rem',
        margin: 0,
    },
    headerIcon: {
        height: '50px',
        marginRight: '15px',
    },
};

export default FoodItemss;


// Animated 