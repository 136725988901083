
// import React, { useContext, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// // import BasketModal from './BasketModal';
// // import { BasketContext } from './BasketContext';
// import { BsFillBasket2Fill } from "react-icons/bs";
// import icon from "../images/icon_2.png";
// import backgroundImage from '../images/backgroundImage.jpg';
// // import BillGenrate from './BillGenrate';
// import { ImSearch } from "react-icons/im";
// import { BasketContexts } from './BasketContexts';
// import Breakfast from '../assets/Shubham/Breakfast.jpg'; // Adjust path accordingly
// import curry from '../assets/Shubham/curry.jpeg'; // Adjust path accordingly
// import lunch from '../assets/Shubham/lunch.webp'; // Adjust path accordingly
// import Biriyani from '../assets/Shubham/Biriyani.jpeg'; // Adjust path accordingly
// import Chinese_Rice_Items from '../assets/Shubham/Chinese_Rice_Items.webp'; // Adjust path accordingly
// import Bread_Items from '../assets/Shubham/Bread_Items.jpg'; // Adjust path accordingly
// import Chicken_Items1 from '../assets/Shubham/Chicken_Items1.jpg'; // Adjust path accordingly
// import Chicken_Kabab from '../assets/Shubham/Chicken_Kabab.jpg'; // Adjust path accordingly
// import Chicken_Liver_Fry from '../assets/Shubham/Chicken_Liver_Fry.png'; // Adjust path accordingly
// import masala_dosa from '../assets/Shubham/masala-dosa.jpg'; // Adjust path accordingly
// import BasketModals from './BasketModels';
// import { toast } from 'react-toastify';
// import BillGenrates from './BillGenrates';

// const CategoryLists = ({ tables }) => {
//     const {hotelId, hotelName, tableNumber, count, billVisible, setBillVisible, GenrateBillFun, Basket, setBasket, handleToggleBasket} = useContext(BasketContexts)
// const categories = [
//     { id: 0, name: 'Breakfast', image : Breakfast },
//     { id: 1, name: 'Dosa', image : masala_dosa },
//     { id: 2, name: 'Curry', image : curry },
//     { id: 3, name: 'Lunch', image : lunch },
//     { id: 4, name: 'Biriyani', image : Biriyani },
//     { id: 5, name: 'Chinese Rice Items', image : Chinese_Rice_Items },
//     { id: 6, name: 'Bread Items', image : Bread_Items },
//     { id: 7, name: 'Chicken Items (Kerala Style Curry)', image : Chicken_Items1 },
//     { id: 8, name: 'Chicken Dry', image : Chicken_Kabab },
//     { id: 9, name: 'Chicken (North Indian)', image : Chicken_Liver_Fry },
//   ];
//     const [searchTerm, setSearchTerm] = useState('');
//     const handleSearchChange = (event) => {
//         setSearchTerm(event.target.value);
//     };

//     const filteredCategories = categories.filter(category =>
//         category.name.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     const navigate = useNavigate();

//     const handleCategoryClick = (category) => {
//         navigate('/FoodItemss', { state: { category} });
//     };



//     return (
//         <div style={styles.container}>
//             <header >

//               <div style={styles.header}>
//               <div style={styles.headerContent}>
//                     <img src={icon} style={styles.headerIcon} alt="Restaurant Icon" />
//                     <h1 style={styles.headerTitle}>{hotelName}</h1>
//                 </div>
//                 <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//                     <button  style={styles.basketButton} onClick={handleToggleBasket} >
//                         <span style={styles.basketCount}>{count}</span>
//                         <BsFillBasket2Fill style={styles.basketIcon} />
//                     </button>
//                     <span style={{ fontSize: 20, fontWeight: '600', marginLeft: '0%' }}>{tableNumber.Table}</span>
//                 </div>
//               </div>
                  

//             </header>

//             {Basket && (
//                 <BasketModals
//                     handleToggleBasket={handleToggleBasket}
//                     tableNumber={tableNumber}
//                     setBasket={setBasket}/>
//             )}
//             {billVisible && (
//                 <div>
//                     <BillGenrates order={tableNumber} />
//                 </div>
//             )}

//             <div style={styles.content}>
//                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0px solid red' }}>
//                     <span style={styles.sectionTitle}>Categories</span>
//                 </div>

//                 <div style={{ position: 'relative', width: '40%' }}>
//                     <ImSearch style={{
//                         position: 'absolute',
//                         top: '50%',
//                         left: '10px',
//                         transform: 'translateY(-50%)',
//                         color: '#7d1a74'
//                     }} />
//                     <input
//                         type="text"
//                         value={searchTerm}
//                         onChange={handleSearchChange}
//                         style={{
//                             width: '100%',
//                             padding: '8px 8px 8px 40px',
//                             border: '1px solid #7d1a74',
//                             backgroundColor: '#f0f0f0',
//                             outline: 'none',
//                             fontSize: '16px'
//                         }}
//                         placeholder='Search'
//                     />
//                     <style jsx>{`
//                         input::placeholder {
//                             color: #7d1a74;
//                         }
//                     `}</style>
//                 </div>

//                 <div style={styles.gridContainer}>
//                     {filteredCategories.map(category => (
//                         <div
//                             key={category.id}
//                             onClick={() => handleCategoryClick(category.name)}
//                             style={styles.categoryCard}
//                         >
//                             <img
//                                 src={category.image}
//                                 alt={category.name}
//                                 style={styles.image}
//                             />
//                             <span style={styles.categoryName}>{category.name}</span>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// const styles = {
//     container: {
//         height: '100vh',
//         backgroundImage: `url(${backgroundImage})`,
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         backgroundRepeat: 'no-repeat',
//         overflowY: "auto",
//         display: 'flex',
//         flexDirection: 'column',
//     },
//     header: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         padding: '20px',
//         backgroundColor: 'rgba(125, 26, 116, 0.9)',
//         color: 'white',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//         flexWrap: 'wrap',
//     },
//     headerContent: {
//         display: 'flex',
//         alignItems: 'center',
//         flexWrap: 'wrap',
//     },
//     headerTitle: {
//         fontSize: '1.5rem',
//         margin: 0,
//     },
//     headerIcon: {
//         height: '50px',
//         marginRight: '15px',
//     },
//     basketButton: {
//         background: 'none',
//         border: 'none',
//         cursor: 'pointer',
//         padding: 0,
//         display: 'flex',
//         alignItems: 'center',
//         position: 'relative',
//         fontSize: '1.5rem',
//     },
//     basketIcon: {
//         color: 'white',
//         fontSize: '2rem',
//         marginLeft: '5px',
//     },
//     basketCount: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         borderRadius: '50%',
//         backgroundColor: 'red',
//         color: 'white',
//         fontWeight: '800',
//         fontSize: '0.8rem',
//         width: '1.5rem',
//         height: '1.5rem',
//         position: 'absolute',
//         top: '-8px',
//         right: '-8px',
//     },
//     content: {
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         gap: '20px',
//         padding: '10px',
//         backgroundColor: 'rgba(255, 255, 255, 0.9)',
//         borderRadius: '20px',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//         margin: '20px',
//     },
//     categoryCard: {
//         padding: '6px',
//         cursor: 'pointer',
//         borderRadius: '10px',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         textAlign: 'center',
//         backgroundColor: 'rgba(255, 205, 255, 0.9)',
//           maxWidth: '680px'
//     },
//     image: {
//         width: '100%',
//         height: '150px',
//         objectFit: 'cover',
//         borderRadius: '8px',
//     },
//     categoryName: {
//         fontSize: '1.2rem',
//         fontWeight: '800',
//         marginTop: '10px',
//     },
//     sectionTitle: {
//         textAlign: 'center',
//         color: '#7d1a74',
//         fontSize: '2rem',
//         fontWeight: '600',
//     },
//     gridContainer: {
//         display: 'grid',
//         gridTemplateRows: '200px',
//         gridAutoRows: '215px',
//         gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
//         gridGap: '40px',
//     },
//     '@media (min-width: 768px)': { // Tablet
//         categoryCard: {
//             flex: '1 1 calc(33.33% - 40px)', // 3 columns on tablet
//             maxWidth: 'calc(33.33% - 40px)',
//             margin: '10px 20px',
//         },
//         sectionTitle: {
//             fontSize: '3rem',
//         },
//     },
//     '@media (min-width: 1024px)': { // Laptop
//         categoryCard: {
//             flex: '1 1 calc(25% - 40px)', // 4 columns on laptop
//             maxWidth: 'calc(25% - 40px)',
//             margin: '10px 20px',
//         },
//         sectionTitle: {
//             fontSize: '4rem',
//         },
//     },
// };

// export default CategoryLists;





// Animated 

import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsFillBasket2Fill } from "react-icons/bs";
import { ImSearch } from "react-icons/im";
import { BasketContexts } from './BasketContexts';
import BasketModals from './BasketModels';
import { toast } from 'react-toastify';
import BillGenrates from './BillGenrates';

import icon from "../images/icon_2.png";
import backgroundImage from '../images/backgroundImage.jpg';
import Breakfast from '../assets/Shubham/Breakfast.jpg';
import curry from '../assets/Shubham/curry.jpeg';
import lunch from '../assets/Shubham/lunch.webp';
import Biriyani from '../assets/Shubham/Biriyani.jpeg';
import Chinese_Rice_Items from '../assets/Shubham/Chinese_Rice_Items.webp';
import Bread_Items from '../assets/Shubham/Bread_Items.jpg';
import Chicken_Items1 from '../assets/Shubham/Chicken_Items1.jpg';
import Chicken_Kabab from '../assets/Shubham/Chicken_Kabab.jpg';
import Chicken_Liver_Fry from '../assets/Shubham/Chicken_Liver_Fry.png';
import masala_dosa from '../assets/Shubham/masala-dosa.jpg';
import EditItemsModels from './EditItemsModels';

const CategoryLists = ({ tables }) => {
    const { hotelId, hotelName, tableNumber, count, billVisible, setBillVisible, GenrateBillFun, Basket, setBasket, handleToggleBasket, editModel, setEditModel, editItem } = useContext(BasketContexts);
    const categories = [
        { id: 0, name: 'Breakfast', image: Breakfast },
        { id: 1, name: 'Dosa', image: masala_dosa },
        { id: 2, name: 'Curry', image: curry },
        { id: 3, name: 'Lunch', image: lunch },
        { id: 4, name: 'Biriyani', image: Biriyani },
        { id: 5, name: 'Chinese Rice Items', image: Chinese_Rice_Items },
        { id: 6, name: 'Bread Items', image: Bread_Items },
        { id: 7, name: 'Chicken Items (Kerala Style Curry)', image: Chicken_Items1 },
        { id: 8, name: 'Chicken Dry', image: Chicken_Kabab },
        { id: 9, name: 'Chicken (North Indian)', image: Chicken_Liver_Fry },
    ];
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {

        setSearchTerm(event.target.value);

    };

    const filteredCategories = categories.filter(category =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const navigate = useNavigate();

    const handleCategoryClick = (category) => {
        navigate('/FoodItemss', { state: { category } });
    };

    useEffect(() => {
        // Add fade-in effect when component loads
        document.querySelectorAll('.fade-in').forEach(element => {
            element.style.opacity = '1';
            element.style.transform = 'translateY(0)';
        });
    }, []);

    return (
        <div style={styles.container}>
            <header style={styles.header} className="fade-in">
                <div style={styles.headerContent}>
                    <img src={icon} style={styles.headerIcon} alt="Restaurant Icon" />
                    <h1 style={styles.headerTitle}>{hotelName}</h1>
                </div>
                <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <button style={styles.basketButton} onClick={handleToggleBasket} >
                        <span style={styles.basketCount}>{count}</span>
                        <BsFillBasket2Fill style={styles.basketIcon} />
                    </button>
                    <span style={{ fontSize: 20, fontWeight: '600', marginLeft: '0%' }}>{tableNumber.Table}</span>
                </div>
            </header>

            {Basket && (
                <BasketModals
                    handleToggleBasket={handleToggleBasket}
                    tableNumber={tableNumber}
                    setBasket={setBasket} />
            )}

            {editModel && (
                <EditItemsModels />
            )}


            {billVisible && (
                <div>
                    <BillGenrates order={tableNumber} />
                </div>
            )}

            <div style={styles.content} className="fade-in">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={styles.sectionTitle}>Categories</span>
                </div>

                <div style={styles.searchContainer}>
                    <ImSearch style={styles.searchIcon} />
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={styles.searchInput}
                        placeholder='Search'
                    />
                </div>

                <div style={styles.gridContainer}>
                    {filteredCategories.map(category => (
                        <div
                            key={category.id}
                            onClick={() => handleCategoryClick(category.name)}
                            style={styles.categoryCard}
                            className="category-card"
                        >
                            <img
                                src={category.image}
                                alt={category.name}
                                style={styles.image}
                            />
                            <span style={styles.categoryName}>{category.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflowY: "auto",
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: 'rgba(125, 26, 116, 0.9)',
        color: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        flexWrap: 'wrap',
        transition: 'all 0.3s ease',
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    headerTitle: {
        fontSize: '1.5rem',
        margin: 0,
    },
    headerIcon: {
        height: '50px',
        marginRight: '15px',
    },
    basketButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        fontSize: '1.5rem',
        transition: 'transform 0.2s ease-in-out',
    },
    basketIcon: {
        color: 'white',
        fontSize: '2rem',
        marginLeft: '5px',
    },
    basketCount: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: 'red',
        color: 'white',
        fontWeight: '800',
        fontSize: '0.8rem',
        width: '1.5rem',
        height: '1.5rem',
        position: 'absolute',
        top: '-8px',
        right: '-8px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // gap: '20px',
        padding: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        margin: '10px',
        opacity: 0,
        transform: 'translateY(20px)',
        transition: 'opacity 0.8s ease, transform 0.8s ease',
    },
    searchContainer: {
        position: 'relative',
        width: '40%',
        // margin: '0 auto',
    },
    searchIcon: {
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        color: '#7d1a74',
        transition: 'color 1.3s ease',
    },
    searchInput: {
        width: '100%',
        padding: '8px 8px 8px 40px',
        border: '1px solid #7d1a74',
        backgroundColor: '#f0f0f0',
        outline: 'none',
        borderRadius: '5px',
        fontSize: '1rem',
        transition: 'border-color 0.9s ease, box-shadow 0.9s ease',
    },
    // gridContainer: {
    //     display: 'grid',
    //     gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    //     gap: '20px',
    //     padding: '10px',
      
    // },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: '20px',
        padding: '10px',
    },
    // categoryCard: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     padding: '2px',
    //     backgroundColor: 'white',
    //     borderRadius: '10px',
    //     boxShadow: '0 2px 10px rgba(125, 26, 116, 2.01)',
    //     cursor: 'pointer',
    //     textAlign: 'center',
    //     transition: 'transform 0.9s ease, box-shadow 0.3s ease',
        
        
    // },
    categoryCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(125, 26, 116, 0.5)',
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        height: 'auto', // Adjusts height based on content
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        objectFit: 'cover',
        // marginBottom: '10px',
    },
    categoryName: {
        fontSize: '1.1rem',
        color: '#7d1a74',
        fontWeight: '400',
    },
    sectionTitle: {
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#7d1a74',
        marginBottom: '15px',
    },
};

// Add the hover effect for category cards using CSS
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
    .category-card:hover {
        transform: scale(1.1);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 1.2);
    }
`, styleSheet.cssRules.length);

export default CategoryLists;



// Animated 
