
import React, { useContext } from 'react';
// import { BasketContext } from './BasketContext';
import icon from "../images/icon_2.png";
import { BasketContexts } from './BasketContexts';

const BillGenrates = ({ order }) => {
    console.log("order--------------------->", order);

    const { visibleBill, setVisibleBill, BillDoneFun,hotelName, setBillVisible } = useContext(BasketContexts);

    const calculateTotal = (items) => {
        return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    };

    const totalAmount = calculateTotal(order.OrderDetails);
    const vat = 0.055 * totalAmount;
    const serviceTax = 0.056 * totalAmount;
    const serviceCharge = 0.10 * totalAmount;
    const netAmount = totalAmount + vat + serviceTax + serviceCharge;

    const closeFun = () => {
        setBillVisible(false);
    };

    return (
        <div style={styles.modalBackdropStyle}>
            <button style={styles.closeButtonStyle} onClick={closeFun}>
                Close
            </button>
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={icon} style={styles.headerIcon} alt="Restaurant Icon" />
                </div>
                <h1 style={styles.title}>{hotelName}</h1>
                <p style={styles.subTitle}>Restaurant Address</p>
                <p style={styles.subTitle}>Bill No : {order.id}</p>
                <p style={styles.subTitle}>Table : {order.Table}</p>

                <div style={styles.itemContainer}>
                    {order.OrderDetails.map((item, index) => (
                        <div key={index} style={styles.item}>
                            <span style={styles.itemName}>{item.name} ({item.price} x {item.quantity})</span>
                            <span style={styles.itemPrice}>{(item.price * item.quantity).toFixed(2)}</span>
                        </div>
                    ))}
                </div>

                <div style={styles.summary}>
                    <div style={styles.summaryItem}>
                        <span>Gross Total:</span>
                        <span>{totalAmount.toFixed(2)}</span>
                    </div>
                    <div style={styles.summaryItem}>
                        <span>VAT 5.5%:</span>
                        <span>{vat.toFixed(2)}</span>
                    </div>
                    <div style={styles.summaryItem}>
                        <span>Service Tax 5.6%:</span>
                        <span>{serviceTax.toFixed(2)}</span>
                    </div>
                    <div style={styles.summaryItem}>
                        <span>Service Charges 10%:</span>
                        <span>{serviceCharge.toFixed(2)}</span>
                    </div>
                    <div style={styles.summaryItem}>
                        <strong>Net Amount:</strong>
                        <strong>{netAmount.toFixed(2)}</strong>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '12%' }}>
                    {/* <button style={styles.paymentButtonGreen} onClick={() => BillDoneFun(order)}> */}
                    <button style={styles.paymentButtonGreen} >
                    <strong> Cash  {netAmount.toFixed(2)}</strong>
                    </button>
                    {/* <button style={styles.paymentButtonYellow} onClick={() => BillDoneFun(order)}> */}
                    <button style={styles.paymentButtonYellow} >
                                           <strong> Online  {netAmount.toFixed(2)}</strong>
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        maxWidth: '400px',
        margin: '0 auto',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        maxHeight: '80vh', // Maximum height of the modal
        overflowY: 'auto', // Enable scrolling
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subTitle: {
        fontSize: '14px',
        textAlign: 'center',
        margin: '5px 0',
    },
    itemContainer: {
        marginTop: '20px',
        borderBottom: '1px dashed #ccc',
        paddingBottom: '10px',
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0',
    },
    itemName: {
        fontSize: '16px',
    },
    itemPrice: {
        fontSize: '16px',
    },
    summary: {
        marginTop: '20px',
    },
    summaryItem: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '5px 0',
    },
    modalBackdropStyle: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    closeButtonStyle: {
        backgroundColor: '#ff4d4f',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '10px 20px',
        fontSize: '18px',
        position: 'absolute',
        top: '20px',
        right: '20px',
    },
    headerIcon: {
        height: '80px',
        marginRight: '15px',
    },
    paymentButtonGreen: {
        padding: '4% 35%',
        backgroundColor: 'green',
        border: 'none',
        borderRadius: 5,
        color: 'white',
        fontSize: 20,
        fontWeight: '600',
        margin: '10px 0px',
        display: 'flex',
        flexDirection:'row'
    },
    paymentButtonYellow: {
        padding: '4% 35%',
        backgroundColor: 'yellow',
        border: 'none',
        borderRadius: 5,
        color: 'black',
        fontSize: 20,
        fontWeight: '600',
    },
};

export default BillGenrates;
